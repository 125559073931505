import { render, staticRenderFns } from "./SetupCheckForDuplicates.vue?vue&type=template&id=792ce4f7&scoped=true&"
import script from "./SetupCheckForDuplicates.vue?vue&type=script&lang=js&"
export * from "./SetupCheckForDuplicates.vue?vue&type=script&lang=js&"
import style0 from "./SetupCheckForDuplicates.vue?vue&type=style&index=0&id=792ce4f7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792ce4f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
