<template>
  <div class="d-flex flex-column">
    <v-app-bar elevation="0" class="transparent general-settings-header px-4">
      <v-toolbar-title class="text-h4 pa-0 ap-dark-gray--text">
        Setup
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <div class="px-8">
      <div class="div-context">
        <v-divider class="context-divider" />
        <v-spacer />
        <v-btn
          outlined
          rounded
          height="45"
          color="ap-grey"
          class="pa-2 bottom-shadow text-none font-weight-light float-right btn-context"
        >
          <v-icon class="ap-light-blue--text"> $mdi-help-circle </v-icon>
          <span class="ap-light-blue--text text-caption ml-1">
            Get more context on this page
          </span>
        </v-btn>
      </div>
      <v-tabs v-model="selectedTab">
        <v-tab
          v-for="(tab, i) in getTabs"
          :key="i"
          class="text-body-1 pa-0 text-none font-weight-black letter-spacing"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="(tab, i) in getTabs" :key="i">
          <SetupCheckForDuplicates v-if="tab.name === 'check_duplicates'" />
          <SetupIndexing v-if="tab.name === 'indexing'" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import SetupCheckForDuplicates from './components/general-settings/SetupCheckForDuplicates'
import SetupIndexing from './components/general-settings/SetupIndexing'

export default {
  components: {
    SetupCheckForDuplicates,
    SetupIndexing,
  },
  data() {
    return { selectedTab: 0 }
  },
  computed: {
    getTabs: function () {
      return [
        {
          name: 'check_duplicates',
          title: 'Check for Duplicates',
        },
        {
          name: 'indexing',
          title: 'Indexing',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.div-context {
  position: relative;
  z-index: 1;
  top: 48px;
}

.btn-context {
  position: absolute;
  right: 0;
  top: -24px;
}

.context-divider {
  width: calc(100% - 272px);
}

.letter-spacing {
  letter-spacing: 0.03em;
}

.bottom-shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
}
</style>
